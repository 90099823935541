import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }
`;
export const List = styled.ul`
  margin-top: 50px;
  width: 300px;
  @media (max-width: 991px) {
    width: auto;
    margin-top: 0;
  }
`;
export const Service = styled.li`
  font-family: montserrat-bold;
  position: relative;
  width: inherit;
  cursor: pointer;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 1.56;
  color: ${(props) => (props.selected ? '#fc5b0e' : '#2d2d2d')};
  @media (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
  a {
    color: ${(props) => (props.selected ? '#fc5b0e' : '#2d2d2d')};
  }

  a:hover {
    color: #fc5b0e;
  }

  &:after {
    z-index: 1;
    content: '';
    display: ${(props) => (props.selected ? 'block' : 'none')};
    position: absolute;
    right: -17px;
    top: 36%;
    width: 34px;
    height: 8px;
    background-image: url(/static/images/orange-arrow.png);
    background-repeat: no-repeat;
    @media (max-width: 991px) {
      width: 20px;
      right: 0px;
      background-size: contain;
    }
  }
`;
export const Info = styled.div`
  width: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ffffff;
  border-left: solid 1px #c9cbce;
  border-top: solid 1px #c9cbce;
  border-bottom: solid 1px #c9cbce;
  @media (max-width: 991px) {
    border: solid 1px #c9cbce;
    margin-top: 1rem;
  }
  &:after {
    z-index: -1;
    content: '';
    top: -1px;
    left: 100%;
    position: absolute;
    border-top: solid 1px #c9cbce;
    border-bottom: solid 1px #c9cbce;
    width: 100%;
    height: 100%;
    background-color: #fff;
    @media (max-width: 991px) {
      display: none;
    }
  }
`;
export const Inner = styled.div`
  padding: 40px 0 40px 55px;

  &.block {
    padding: 0;
    p {
      font-size: .8em;
      color: #666;
      padding: 0;
      margin: 0 0 20px;
      line-height: 1.56;
      text-align: justify;
    }
  }
  @media (max-width: 991px) {
    padding: 1rem;
    &.block {
      padding: 0;
    }
  }
  h2 {
    opacity: 0.8;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.44;
    color: #000000;
    margin-bottom: 25px;
  }
  li {
    font-size: 15px;
    line-height: 1.53;
    color: #000000;
  }
  .outer-list > li {
    position: relative;
    margin: 5px 0;
    padding-left: 24px;
    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 7px;
      width: 7px;
      height: 7px;
      transform: rotate(-45deg);
      border-bottom: solid 1px #000;
      border-right: solid 1px #000;
    }
  }
  .inner-list {
    margin: 10px 0;
    & > li {
      padding-left: 26px;
      &:before {
        content: '— ';
        position: absolute;
        left: 28px;
      }
    }
  }
  h4 {
    margin: 1.5em 0 1em;
  }
  & > p {
    width: auto;
    margin: 25px 0;
    /*
    width: 100px;
    */
    a {
      font-size: 14px;
      font-weight: 600;
      color: #fc5b0e;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: -14px;
        top: 5px;
        width: 8px;
        height: 8px;
        transform: rotate(-45deg);
        border-bottom: solid 2px #fc5b0e;
        border-right: solid 2px #fc5b0e;
      }
    }
  }
`;
