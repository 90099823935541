import React from 'react';
import { Link } from 'gatsby';

import { Wrapper, List, Service, Info, Inner } from './tabsStyled';

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedService: props.selected };
  }

  getServices = () => {
    const services = this.props.items.sort((firstChild, secondChild) => {
      return firstChild.frontmatter.index - secondChild.frontmatter.index;
    });

    let serviceList = [];
    let serviceInfo = [];

    services.forEach((service, index) => {
      serviceList.push(this.getServiceItem(service, index));
      serviceInfo.push(this.getServiceInfo(service, index));
    });

    return (
      <Wrapper>
        <List>{serviceList}</List>
        <Info>{serviceInfo}</Info>
      </Wrapper>
    );
  };

  getTitle = (title) => {
    const separateTitle = title.split('\\n');
    return (
      <>
        {separateTitle[0]}
        <br />
        {separateTitle[1]}
      </>
    );
  };

  getServiceItem = (service, index) => {
    const title = this.getTitle(service.frontmatter.title);
    const link = this.props.getLink ? this.props.getLink(service) : '';

    if (this.props.getLink) {
      return (
        <Service key={index} selected={this.state.selectedService === service.frontmatter.block}>
          <Link to={link}>{title}</Link>
        </Service>
      );
    } else {
      return (
        <Service
          key={index}
          onClick={() => this.setState({ selectedService: service.frontmatter.block })}
          selected={this.state.selectedService === service.frontmatter.block}
        >
          {title}
        </Service>
      );
    }
  };

  getServiceInfo = (service, index) => {
    return this.state.selectedService === service.frontmatter.block ? (
      <Inner key={index} dangerouslySetInnerHTML={{ __html: service.html }} />
    ) : null;
  };

  render() {
    return <>{this.getServices()}</>;
  }
}
