import { Link } from 'gatsby';
import styled from 'styled-components';

export const Title = styled.h1`
    margin-bottom: 40px;
    @media (max-width: 991px) {
        margin-bottom: 15px;
    }
`
export const Tab = styled(Link)`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 255px;
  height: 72px;
  border-radius: 4px;
  padding: 20px;
  background: linear-gradient(#30353b, #242930);
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: ${props => props.selected ? '#fc5b0e' : '#fff'};
  img {
    height: 34px;
    margin-right: 25px;
  }
  &:after {
    display: ${props => props.selected ? 'block' : 'none'};
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fc5b0e;
    border-radius: 4px;
    @media (max-width: 991px) {
      bottom: -10px;
      display: none;
    }
  }
`

export const Tabs = styled.div`
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }

  ${Tab} {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
`


export const ProductsBlock = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 991px) {
        display: block;
    }              
    li {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 21px;
      height: auto;
      max-width: 32.6%;
      margin: 0 1% 1% 0;
      border-radius: 4px;
      background-image: linear-gradient(#262b31, #262b31), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5));
      transition: .15s all ease-out;
      bottom: 0;
      position: relative;
      &:nth-child(3n) {
          margin-right: 0;
      }
      @media (max-width: 991px) {
        width: 100%;
        max-width: none;
        margin: 0 0 .1rem;
      }          

      &:hover {
        box-shadow: inset 0 0 50px 3px rgba(255, 255, 255, .2);
      }
      .product-title{
          display: flex;
          align-items: center;
          margin: 0 0 10px;
      }

      img {
        height: 42px;
        max-width: 63px;
        margin: 0 23px 0 0;
      }
      h2 {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.3333;
        color: #FFFFFF;
      }
      .product-info {
        max-width: 275px;
        height: 56px;
        font-size: 13px;
        line-height: 1.4;
        color: #D3D3D3;
        margin: 0 0 15px;
        @media (max-width: 1129px) {
          height: auto;
          font-size: 14px;
          margin: 0 0 15px;
        }

      } 
      a {
        font-size: 14px;
        font-weight: 600;
        color: #EA662F;
        position: relative;
        transition: .2s all ease-out;
        &:after {
          content: '';
          position: absolute;
          right: -14px;
          top: 5px;
          width: 8px;
          height: 8px;
          transform: rotate(-45deg);
          border-bottom: solid 2px #fc5b0e;
          border-right: solid 2px #fc5b0e;
        }
      }
      a:hover{
        color: #FA763F;
      }
    }
  }   
` 
